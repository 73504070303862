<template>
   <div>
    <div class="one">
      <base-card :width="'48vw'" style="margin-right: 20px" @detailInfo="baseInfo">
        <template v-slot:title>
          <svg-icon icon-class="base"></svg-icon>
          <span class="title">基本资料</span>
        </template>
        <template v-slot:content>
          <div class="content">
            <ul>
              <li>
                <span class="bt">单位名称</span>
                <span >{{ userInfo.name }}</span>
              </li>
              <li>
                <span class="bt">监督岗数量</span>
                <span>{{ userInfo.sentry_num }}</span>
              </li>
            </ul>
          </div>
        </template>
      </base-card>
      <base-card class="two" width="40vw" @detailInfo="notifyInfo">
        <template v-slot:title>
          <svg-icon icon-class="job"></svg-icon>
          <span class="title">工作通知</span>
        </template>
        <template v-slot:content>
          <div class="content">
            <ul>
              <li style="line-height: 1.3;font-size: 18px;" v-for="(item,index) in notifyList" :key="index">
                <span style="color: black">[报事]{{ item.content }}</span>
              </li>
            </ul>
          </div>
        </template>
      </base-card>
    </div>
    <base-card :rightButton="false" class="three" :width="'100%'">
      <template v-slot:title>
        <svg-icon icon-class="fast"></svg-icon>
        <span class="title">快捷功能</span>
      </template>
      <template v-slot:content>
        <div class="content">
          <div class="box" v-for="(item,index) in boxList" :key="index">
            <router-link :to="item.link">
              <div class="box-o">
                <img :src="item.img" />
              </div>
              <div class="box-bottom"><span>{{ item.title }}</span></div>
            </router-link>
          </div>
        </div>
      </template>
    </base-card>
    <base-card class="four" :rightButton="false" :width="'100%'">
      <template v-slot:title>
        <svg-icon icon-class="notify"></svg-icon>
        <span class="title">温馨提示</span>
      </template>
      <template v-slot:content>
        <div class="content">
          <span>关于常规业务流程、操作方法等问题，请直接电话咨询管理员。</span>
          <span>咨询时间：工作日 9:00 - 17:00</span>
        </div>
      </template>
    </base-card>
  </div>
</template>

<script>
import BaseCard from "./BaseCard";
import { reportList,getUserInfo,juniorNum } from "@/api";
export default {
  name: "home",
  async created () {
    try{
      const notifyList = await reportList({type:2});
      this.notifyList = notifyList.data.data.data;
      const res = await getUserInfo();
      this.userInfo = res.data.data
      const resNums = await juniorNum({
        type:3,
        id:localStorage.getItem("department_id")
      });
      const {sentry_num} = resNums.data.data;
      this.userInfo.sentry_num = sentry_num
      localStorage.setItem("userInfo",JSON.stringify(this.userInfo));
      if(this.userInfo.type == 2 && !localStorage.getItem("isAsk")) {
        localStorage.setItem("isAsk",true)
        const res = await this.$open({ msg:"当前密码为初始密码,是否跳转修改密码?" });
        if(res) {
          this.$router.push("/changePwd");
          return;
        }
      }

    }catch(e){}
  },
  data() {
    return {
      dialogVisible: false,
      timer: null,
      userInfo: "",
      boxList: [
        {
          link: "/supervision/supervisionCreate",
          img: require("../../assets/images/create.png"),
          title: "创建监督岗",
        },
        {
          link: "/supervision/supervision",
          img: require("../../assets/images/manager.png"),
          title: "管理监督岗",
        },
        {
          link: "/supervision/person-entry",
          img: require("../../assets/images/manager.png"),
          title: "录入监督岗人员",
        },
        {
          link: "/supervision/data-statisical",
          img: require("../../assets/images/tongji.png"),
          title: "监督岗数据统计",
        },
        {
          link: "/list/data-statisical",
          img: require("../../assets/images/report.png"),
          title: "报事数据统计",
        },
      ],
      notifyList: [],
    };
  },
  components: {
    BaseCard,
  },
  watch:{
    notifyList:{
      handler(val){
        if(val.length > 5) {
          clearInterval(this.timer)
          this.timer = setInterval(this.scrollList,2000)
        }else {
          clearInterval(this.timer)
        }
      },
      immediate:true,
      deep:true
    }
  },
  methods: {
    baseInfo() {
      this.$router.push({
        path: "/userInfo",
      });
    },
    notifyInfo() {
      this.$router.push({
        path: "/list/list-ting",
      });
    },
    scrollList() {
      setTimeout(() => {
        this.notifyList.push(this.notifyList[0]);
        this.notifyList.shift();
      }, 500);
    },
  },
  destroyed() {
    clearInterval(this.timer);
  },
};
</script>

<style lang="scss" scoped>

.title{
  margin-left: 5px;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #E32322;
}

ul li{
  margin-bottom: 10px;
  .bt{
    display: inline-block;
    text-align: center;
    line-height: 26px;
    width: 150px;
    height: 26px;
    background: #E4E4E4;
    margin-right: 15px;
    border-radius: 4px;
    color: #333333;
  }
}

.one{
  display: flex;
  justify-content: space-between;
}
.two{
  ul,li{
    margin-left: 10px;
    color: red;
  }
}
.three {
  .content {
    display: flex;
    height: 200px;
    box-sizing: border-box;
    align-items: center;
    margin-left: 44px;
    margin-top: -15px;
    .box{
      width: 120px;
      height: 160px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-left: 86px;
      &:first-child{
        margin-left: 0;
      }
      .box-o{
        width: 120px;
        height: 120px;
        border-radius: 50%;
        display: flex;
        flex-shrink: 0;
        img{
          width: 100%;
          height: 100%;
        }
      }
      .box-bottom{
        margin-top: 10px;
        display: flex;
        justify-content: center;
      }
    }
  }
}
.four{
  .content{
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    span{
      line-height: 1.8;
      color: #333333 ;
      font-size: 16px;
      font-weight: bold;
    }
  }
}
</style>
